





























































import { defineComponent } from "@vue/composition-api";
import weui from "weui.js";
import axios from "@/helpers/axios";
import { Toast } from "vant";

export default defineComponent({
  data() {
    return {
      data: {
        cashType: 0,
        cashAllowAmount: 0,
        cashMax: 0
      },
      amount: "",
      cashAccountCast: "",
      cashAccountAct: "",
      canCash: false,
      msg: "",
      phoneCode: "",
      codeData: {
        waitTime: 60,
        codeButtonColor: "#f60",
        codeWaitTime: 60,
        codeButtonText: "发送验证码"
      },
      fee: 0
    };
  },
  watch: {
    amount: function(value, oldValue) {
      if (value && !/^(\d)+(\.\d{0,2})?$/.test(value)) {
        this.amount = oldValue;
        return;
      }
      const business = this.data;
      if (value && business) {
        // var number = Number(value) * 100;
        const number = Number(value);
        let fee = 0;
        if (this.data.cashType == 1) {
          fee = Math.round(number * 0.1) * 0.01;
          if (fee < 1) {
            fee = 1;
          } else if (fee > 25) {
            fee = 25;
          }
          this.fee = fee;
        }
        console.log("number: " + number);
        console.log("fee: " + fee);
        if (number > business.cashAllowAmount) {
          this.preventApply("提现金额已超过可提现额度");
        } else if (number + fee > business.cashAllowAmount) {
          this.preventApply("提现金额加手续费已超过可提现额度");
        } else if (number > business.cashMax) {
          this.preventApply("提现金额已超过单笔提现额度");
        } else if (number >= 1) {
          this.canCash = true;
          this.msg = "";
        } else {
          this.preventApply("提现金额不能低于1元");
        }
      } else {
        this.preventApply();
      }
    }
  },
  methods: {
    async sendPhoneCode() {
      if (this.codeData.codeWaitTime == this.codeData.waitTime) {
        // send and count down
        // send
        const res = await axios
          .post("/api/manage/account/withdraw/applyPhoneCode")
          .then(() => true)
          .catch(() => false);
        if (!res) return;
        Toast.success("发送成功");
        // count down on success
        this.countdown();
      }
    },
    countdown: function() {
      const codeWaitTime = this.codeData.codeWaitTime;
      if (codeWaitTime == 0) {
        this.codeData.codeWaitTime = this.codeData.waitTime;
        this.codeData.codeButtonColor = "#f60";
        this.codeData.codeButtonText = "发送验证码";
        console.log("ok now");
      } else {
        this.codeData.codeWaitTime = codeWaitTime - 1;
        this.codeData.codeButtonColor = "#848484";
        this.codeData.codeButtonText = "重新获取 (" + codeWaitTime + ")";
        console.log("wait " + codeWaitTime);
        setTimeout(() => {
          this.countdown();
        }, 1000);
      }
    },
    preventApply: function(msg = "") {
      this.msg = msg || "";
      this.canCash = false;
    },
    async getData() {
      const data = await axios
        .post("/api/manage/account/withdraw/applyInfo")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!data) return;
      if (!data.wxName) {
        weui.alert("您还未设置提现账户", () => {
          this.$router.go(-1);
        });
      }
      this.data = data;
    },
    async apply() {
      if (!this.canCash) {
        weui.alert(this.msg);
        return;
      }
      const res = await axios
        .post("/api/manage/review/applyForWithdraw", {
          amount: Math.floor(Number(this.amount) * 100),
          phoneCode: this.phoneCode,
          token: localStorage.token
        })
        .then(() => true)
        .catch(() => false);
      if (!res) return;
      weui.toast("申请提交成功", () => {
        this.$router.go(-1);
      });
    }
  },
  created: function() {
    this.getData();
  }
});
